<template>
  <WidgetSettingsOverlayStyled
    :class="{
      'configure-dimensions': showDimensionsSelected,
    }"
  >
    <Header :title="headerTitle" />
    <MainStyled :ref="'main'">
      <DimensionsAvailable
        :ref="'available'"
        :dimensionsSelected="dimensionsSelected"
        :selectedWidget="selectedWidget"
        @addDimension="addDimension"
        @removeDimension="removeDimension"
        @unselectWidgetType="unselectWidgetType"
      />
      <DimensionsSelected
        v-if="showDimensionsSelected"
        :ref="'selected'"
        :dimensionsSelected="dimensionsSelected"
        :widgetDimensions="widgetDimensions"
        :selectedWidget="selectedWidget"
        @removeDimension="removeDimension"
        @toggleTargetValueEnabled="toggleTargetValueEnabled"
        @updateTargetValue="updateTargetValue"
        @updateChartType="updateChartType"
      />
      <ConfigureWidget
        :ref="'widget'"
        :title.sync="title"
        :selectedWidget="selectedWidget"
        :widgetSettings="widgetSettings"
        :widgetsAvailable="widgetsMapped"
        :dimensionsSelected="dimensionsSelected"
        @setWidgetType="setWidgetType"
        @updateTitle="title = $event"
        @changeWidgetSettings="changeWidgetSettings"
      />
      <WidgetPreview
        :ref="'preview'"
        :showWidgetPreview="showWidgetPreview"
        :gridItemID="gridItemID"
        :dashboardType="dashboardType"
        :widget="widgetPreviewObj"
      />
    </MainStyled>
    <Footer :canSave="canSave" @save="confirm" @cancel="$emit('close')" @scrollTo="scrollTo" />
  </WidgetSettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import Header from './WidgetSettingsOverlay/Header'
import DimensionsAvailable from './WidgetSettingsOverlay/DimensionsAvailable'
import DimensionsSelected from './WidgetSettingsOverlay/DimensionsSelected'
import ConfigureWidget from './WidgetSettingsOverlay/ConfigureWidget'
import WidgetPreview from './WidgetSettingsOverlay/WidgetPreview'
import Footer from './WidgetSettingsOverlay/Footer'

import { getRolesFromToken } from '@common/utils'

import GRID_ITEM_CREATE_MUTATION from '#/graphql/operations/dashboard/gridItem/create.gql'
import GRID_ITEM_UPDATE_MUTATION from '#/graphql/operations/dashboard/gridItem/update.gql'

import HISTOGRAM_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createHistogramWidgetSettings.gql'
import HISTOGRAM_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateHistogramWidgetSettings.gql'
import CYCLE_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createCycleTableWidgetSettings.gql'
import CYCLE_TABLE_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateCycleTableWidgetSettings.gql'
import DASHBOARD_INFORMATION_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createDashboardInformationWidgetSettings.gql'
import DASHBOARD_INFORMATION_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateDashboardInformationWidgetSettings.gql'
import PIVOT_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createPivotTableWidgetSettings.gql'
import PIVOT_TABLE_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updatePivotTableWidgetSettings.gql'
import WIDGET_TYPES_QUERY from '#/graphql/widgetTypes/widgetTypes.gql'
import ERROR_STATISTICS_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createErrorStatisticsWidgetSettings.gql'
import ERROR_STATISTICS_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateErrorStatisticsWidgetSettings.gql'
import ERROR_LOG_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createErrorLogWidgetSettings.gql'
import ERROR_LOG_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateErrorLogWidgetSettings.gql'
import TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createTableWidgetSettings.gql'
import TABLE_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateTableWidgetSettings.gql'
import DRILLING_TABLE_WIDGET_SETTINGS_CREATE from '#/graphql/operations/dashboard/widgetSettings/createDrillingTableWidgetSettings.gql'
import DRILLING_TABLE_WIDGET_SETTINGS_UPDATE from '#/graphql/operations/dashboard/widgetSettings/updateDrillingTableWidgetSettings.gql'

const WidgetSettingsOverlayStyled = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr 3rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main'
    'footer';
`

const MainStyled = styled('div')`
  grid-area: main;
  display: grid;
  overflow: auto;
  scroll-behavior: smooth;
  grid-template-rows: repeat(4, calc(100vh - 10rem));
  grid-template-columns: 1fr;
  grid-template-areas:
    'dimensions-available'
    'configure-widget'
    'dimensions-selected'
    'widget-preview';

  @media (min-width: 768px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'dimensions-available configure-widget'
      'dimensions-selected widget-preview';
  }
`

export default {
  props: {
    args: {
      type: Object,
      required: true,
    },
  },
  components: {
    WidgetSettingsOverlayStyled,
    Header,
    MainStyled,
    DimensionsAvailable,
    DimensionsSelected,
    ConfigureWidget,
    WidgetPreview,
    Footer,
  },
  data() {
    return {
      isMutating: false,
      mode: 'create',
      dashboard: null,
      widget: {
        title: null,
        type: null,
        dimensions: [],
      },
      widgetTypes: [],
      widgetDimensions: [],
      widgetSettings: {},
      title: '',
      selectedWidgetTypeName: null,
      gridItemID: 'preview',
      abstractWidgetSettings: {},
    }
  },
  watch: {
    args: {
      handler(args) {
        this.dashboard = args.dashboard
        if (args.gridItem) {
          this.gridItem = args.gridItem
        }
        if (args.widget) {
          this.widget = args.widget
          this.title = get(this.widget, 'title', '')
          this.selectedWidgetTypeName = get(this.widget, 'type', null)
          this.widgetDimensions = get(this.widget, 'dimensions', []).slice()
          this.widgetSettings = get(this.widget, 'widgetSettings', {})
          this.abstractWidgetSettings = get(this.widget, 'abstractWidgetSettings', {})
        }
        if (args.mode) {
          this.mode = args.mode
        }
        if (args.gridItemID) {
          this.gridItemID = args.gridItemID
        }
      },
      immediate: true,
    },
  },
  computed: {
    selectedWidget() {
      return this.widgetTypes.find(f => f.name === this.selectedWidgetTypeName)
    },
    dashboardType() {
      return get(this.dashboard, 'type', 'FLEET')
    },
    widgetPreviewObj() {
      const t = this.title
      const obj = {
        title: t,
        type: get(this.selectedWidget, 'name'),
        dimensions: this.widgetDimensions.slice(),
        widgetSettings: this.widgetSettings,
      }
      obj.dimensions.forEach((widget, index) => {
        widget.position = index
      })
      return obj
    },
    showWidgetPreview() {
      const hasWidget = !!this.selectedWidget
      const minDimensions = get(this.selectedWidget, 'minDimensions')
      const enoughDimensions = this.dimensionsSelected.length >= minDimensions
      return hasWidget && enoughDimensions
    },
    showDimensionsSelected() {
      return this.dimensionsSelected.length > 0
    },
    dimensionsSelected() {
      return this.widgetDimensions.map(wd => wd.assetDimension)
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasTalpaRoles() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product') || this.userRoles.includes('talpa-basic')
    },
    widgetsMapped() {
      return this.widgetTypes.map(widget => {
        const hasIncompatibleDimension = this.dimensionsSelected.some(dimension => {
          const compatibleWidgets = get(dimension, 'compatibleWidgets', [])
          const isNotCompatible = !compatibleWidgets.find(cw => cw.id === widget.id)
          return isNotCompatible
        })
        const hasTooManyDimensions = widget.maxDimensions < this.dimensionsSelected.length
        const hasTooFewDimensions = widget.minDimensions > this.dimensionsSelected.length
        const incompatibleDashboardType = this.dashboardType.includes('FLEET')
          ? widget.needsSingleViewDashboard
          : widget.needsFleetViewDashboard

        const isDisabled =
          widget.name === 'DASHBOARD_INFORMATION'
            ? !this.hasTalpaRoles || hasIncompatibleDimension
            : hasIncompatibleDimension || hasTooManyDimensions || hasTooFewDimensions || incompatibleDashboardType
        let reason = ''
        if (incompatibleDashboardType) {
          reason = this.$t('messages.incompatibleDashboardType')
        } else if (widget.maxDimensions === 0 && widget.name !== 'DASHBOARD_INFORMATION') {
          reason = this.$t('messages.noDimensionsAllowed')
        } else if (hasTooFewDimensions) {
          reason = this.$t('messages.hasTooFewDimensions')
        } else if (hasTooManyDimensions) {
          reason = this.$t('messages.hasTooManyDimensions')
        } else if (!this.hasTalpaRoles) {
          reason = this.$t('messages.notAvailable')
        }

        const noDimensionsSelected =
          this.dimensionsSelected.length === 0 && !incompatibleDashboardType && widget.name !== 'DASHBOARD_INFORMATION'

        return {
          ...widget,
          isEnabled: !isDisabled || noDimensionsSelected,
          reason,
        }
      })
    },
    canSave() {
      return this.showWidgetPreview
    },
    headerTitle() {
      return this.$tc(`titles.${get(this.args, 'mode', 'add')}Widget`)
    },
    isSingleViewDashboard() {
      const t = get(this.dashboard, 'type', 'unknown')
      return t === 'SINGLE_ASSET' || t === 'TEMPLATE_SINGLE_ASSET'
    },
    sortedWidgets() {
      const widgets = { ...this.widget }
      this.widgetDimensions.map((dimension, index) => {
        dimension.position = index
      })
      widgets.dimensions = this.widgetDimensions
      return widgets
    },
  },
  methods: {
    scrollTo(page) {
      const main = this.$refs['main']
      let child
      if (page === 1) {
        child = this.$refs['available']
      }
      if (page === 3) {
        child = this.$refs['selected']
      }
      if (page === 2) {
        child = this.$refs['widget']
      }
      if (page === 4) {
        child = this.$refs['preview']
      }
      if (main && child) {
        const offset = child.$el.offsetTop - 56
        main.scrollTop = offset
      }
    },
    unselectWidgetType() {
      this.selectedWidgetTypeName = null
    },
    setWidgetType(widget) {
      const name = get(widget, 'name')
      if (name === this.selectedWidgetTypeName) {
        this.selectedWidgetTypeName = null
      } else {
        this.selectedWidgetTypeName = name
        // reset the widget settings as user changed the widget type
        this.widgetSettings = {}
      }
    },
    addDimension(dimension) {
      this.widgetDimensions.push({
        assetDimension: dimension,
        targetValueEnabled: false,
        targetValue: 0,
        graphType: 'LINE',
        position: this.widgetDimensions.length,
      })
    },
    toggleTargetValueEnabled(dimension) {
      const dim = this.widgetDimensions.find(dim => dim.assetDimension.id === dimension.id)
      dim.targetValueEnabled = !dim.targetValueEnabled
    },
    updateTargetValue(dimension, value) {
      const dim = this.widgetDimensions.find(dim => dim.assetDimension.id === dimension.id)
      dim.targetValue = value
    },
    updateChartType(dimension, value) {
      const dim = this.widgetDimensions.find(dim => dim.assetDimension.id === dimension.id)
      dim.graphType = value
    },
    removeDimension(dimension) {
      this.widgetDimensions = this.widgetDimensions.filter(d => d.assetDimension.id !== dimension.id)
    },
    setWidgetTitle(title) {
      this.title = title
    },
    changeWidgetSettings(widgetSettings) {
      this.widgetSettings = widgetSettings
    },
    confirm() {
      if (this.mode === 'add') {
        this.createWidget()
      } else {
        this.updateWidget()
      }
    },
    async createWidget() {
      if (!get(this.dashboard, 'id', null) || this.isMutating) {
        return
      }
      this.isMutating = true
      // create the widget settings and get the id
      const widgetSettingsId = await this.createWidgetSettings()
      const maxY = this.dashboard.gridItems.reduce((maxY, item) => {
        return Math.max(item.y, maxY)
      }, -1)
      const data = {
        w: this.selectedWidget.defaultWidth,
        h: this.selectedWidget.defaultHeight,
        x: 0,
        y: maxY + 1,
        dashboard: {
          connect: {
            id: this.dashboard.id,
          },
        },

        widget: {
          create: {
            ...this.sortedWidgets,
            type: this.selectedWidgetTypeName,
            widgetType: {
              connect: {
                name: this.selectedWidgetTypeName,
              },
            },
            abstractWidgetSettings: {
              create: {
                widgetSettingsId: widgetSettingsId,
                widgetSettingsType: this.selectedWidgetTypeName,
              },
            },
            title: this.title,
            dimensions: {
              create: this.widgetDimensions.map((d, index) => ({
                targetValue: d.targetValue,
                targetValueEnabled: d.targetValueEnabled,
                graphType: d.graphType,
                position: index,
                assetDimension: {
                  connect: {
                    id: d.assetDimension.id,
                  },
                },
              })),
            },
          },
        },
      }
      const res = await this.$apollo.mutate({
        mutation: GRID_ITEM_CREATE_MUTATION,
        variables: {
          data,
        },
      })
      this.isMutating = false
      if (res) {
        this.$root.$emit('closeOverlay')
      }
    },
    async updateWidget() {
      if (this.isMutating) {
        return
      }
      this.isMutating = true
      const { widget: { dimensions } = {}, widgetDimensions } = this
      const dimensionActivities = {
        delete: dimensions.filter(before => !widgetDimensions.find(after => after.id === before.id)),
        update: widgetDimensions.filter(after => dimensions.find(before => after.id === before.id)),
        add: widgetDimensions.filter(after => !dimensions.find(before => after.id === before.id)),
      }

      const id = this.gridItemID
      const widget = { ...this.sortedWidgets }
      delete widget['__typename']
      delete widget['id']
      if (!id) {
        return
      }
      // update widgetSettings
      const widgetSettingsId = await this.updateWidgetSettings()
      delete widget['widgetSettings']
      const update = {
        update: {
          widgetSettingsId: widgetSettingsId,
          widgetSettingsType: this.selectedWidgetTypeName,
        },
      }
      const create = {
        create: {
          widgetSettingsId: widgetSettingsId,
          widgetSettingsType: this.selectedWidgetTypeName,
        },
      }
      let abstractSettings = get(this.abstractWidgetSettings, 'id', '') ? { ...update } : { ...create }

      const data = {
        widget: {
          update: {
            ...widget,
            type: this.selectedWidgetTypeName,
            widgetType: {
              connect: {
                name: this.selectedWidgetTypeName,
              },
            },
            title: this.title,
            dimensions: {
              deleteMany: {
                id_in: dimensionActivities.delete.map(dd => dd.id),
              },
              create: dimensionActivities.add.map(da => ({
                ...da,
                assetDimension: {
                  connect: {
                    id: da.assetDimension.id,
                  },
                },
                id: undefined,
              })),
              update: dimensionActivities.update.map(du => {
                const data = {
                  ...du,
                  assetDimension: {
                    connect: {
                      id: du.assetDimension.id,
                    },
                  },
                }
                delete data.id
                delete data[`__typename`]
                return {
                  data,
                  where: {
                    id: du.id,
                  },
                }
              }),
            },
            abstractWidgetSettings: {
              ...abstractSettings,
            },
          },
        },
      }

      const res = await this.$apollo.mutate({
        mutation: GRID_ITEM_UPDATE_MUTATION,
        variables: {
          data,
          where: {
            id,
          },
        },
      })
      this.isMutating = false
      if (res) {
        this.$root.$emit('closeOverlay')
      }
    },

    async createWidgetSettings() {
      let id = null
      if (this.selectedWidgetTypeName === 'HISTOGRAM') {
        const res = await this.$apollo.mutate({
          mutation: HISTOGRAM_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createHistogramWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'CYCLE_TABLE') {
        const res = await this.$apollo.mutate({
          mutation: CYCLE_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createCycleTableWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'DASHBOARD_INFORMATION') {
        const res = await this.$apollo.mutate({
          mutation: DASHBOARD_INFORMATION_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createDashboardInformationWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'PIVOT_TABLE') {
        const data = { ...this.widgetSettings }
        /** Columns */
        const createColumns = data?.addColumns?.map(column => ({
          assetDimension: {
            connect: {
              id: column.assetDimension.id,
            },
          },
          isEnabled: true,
        }))
        if (createColumns?.length > 0) {
          data.userSelectedColumnDimensions = {
            create: createColumns,
          }
        }
        /** Rows */
        const createRows = data?.addRows?.map(row => ({
          assetDimension: {
            connect: {
              id: row.assetDimension.id,
            },
          },
          isEnabled: true,
        }))
        if (createRows?.length > 0) {
          data.userSelectedRowDimensions = {
            create: createRows,
          }
        }
        delete data['addColumns']
        delete data['removeColumns']
        delete data['addRows']
        delete data['removeRows']
        const res = await this.$apollo.mutate({
          mutation: PIVOT_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: data,
          },
        })
        id = get(res, 'data.createPivotTableWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'ERROR_STATISTICS') {
        const res = await this.$apollo.mutate({
          mutation: ERROR_STATISTICS_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createErrorStatisticsWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'ERROR_LOG') {
        const res = await this.$apollo.mutate({
          mutation: ERROR_LOG_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createErrorLogWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'TABLE') {
        const res = await this.$apollo.mutate({
          mutation: TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createTableWidgetSettings.id', null)
      } else if (this.selectedWidgetTypeName === 'DRILLING_TABLE') {
        const res = await this.$apollo.mutate({
          mutation: DRILLING_TABLE_WIDGET_SETTINGS_CREATE,
          variables: {
            data: this.widgetSettings,
          },
        })
        id = get(res, 'data.createDrillingTableWidgetSettings.id', null)
      }
      return id
    },
    async updateWidgetSettings() {
      let id = null
      // if widget settings is not present create a new settings and return the id
      const widgetSettingsId = this.widgetSettings?.id ?? null
      if (!widgetSettingsId) {
        return this.createWidgetSettings()
      }
      if (this.selectedWidgetTypeName === 'HISTOGRAM') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: HISTOGRAM_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateHistogramWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'CYCLE_TABLE') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: CYCLE_TABLE_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateCycleTableWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'DASHBOARD_INFORMATION') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: DASHBOARD_INFORMATION_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateDashboardInformationWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'ERROR_STATISTICS') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: ERROR_STATISTICS_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateErrorStatisticsWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'ERROR_LOG') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: ERROR_LOG_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateErrorLogWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'TABLE') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: TABLE_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateTableWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'DRILLING_TABLE') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']
        const res = await this.$apollo.mutate({
          mutation: DRILLING_TABLE_WIDGET_SETTINGS_UPDATE,
          variables: {
            data: data,
            where: {
              id: this.widget.widgetSettings.id,
            },
          },
        })
        id = get(res, 'data.updateDrillingTableWidgetSettings.id', null)
      }
      if (this.selectedWidgetTypeName === 'PIVOT_TABLE') {
        const data = { ...this.widgetSettings }
        delete data['id']
        delete data['__typename']

        /**Columns */
        const createColumns = data?.addColumns?.map(column => ({
          assetDimension: {
            connect: {
              id: column.assetDimension.id,
            },
          },
          isEnabled: true,
        }))
        const deleteColumns = data?.removeColumns?.map(column => column.id)

        /**Rows */
        const createRows = data?.addRows?.map(row => ({
          assetDimension: {
            connect: {
              id: row.assetDimension.id,
            },
          },
          isEnabled: true,
        }))
        const deleteRows = data?.removeRows?.map(row => row.id)

        delete data['addColumns']
        delete data['removeColumns']
        delete data['addRows']
        delete data['removeRows']

        if (createColumns?.length > 0 || createRows?.length > 0) {
          if (createColumns?.length > 0) {
            const createColumnData = data
            delete createColumnData['userSelectedRowDimensions']
            createColumnData.userSelectedColumnDimensions = {
              create: createColumns,
            }
          }

          if (createRows?.length > 0) {
            const createRowData = data
            delete createRowData['userSelectedColumnDimensions']
            createRowData.userSelectedRowDimensions = {
              create: createRows,
            }
          }

          id = await this.updatePivotTableSettingsMutation(data, this.widget.widgetSettings.id)
        }

        if (deleteColumns?.length > 0 || deleteRows?.length > 0) {
          if (deleteColumns?.length > 0) {
            const deleteColumnData = data
            delete deleteColumnData['userSelectedRowDimensions']
            deleteColumnData.userSelectedColumnDimensions = {
              deleteMany: {
                id_in: deleteColumns,
              },
            }
          }

          if (deleteRows?.length > 0) {
            const deleteRowData = data
            delete deleteRowData['userSelectedColumnDimensions']
            deleteRowData.userSelectedRowDimensions = {
              deleteMany: {
                id_in: deleteRows,
              },
            }
          }

          id = await this.updatePivotTableSettingsMutation(data, this.widget.widgetSettings.id)
        }

        if (createColumns?.length < 1 && deleteColumns?.length < 1 && createRows?.length < 1 && deleteRows?.length < 1) {
          const updateData = data
          delete updateData['userSelectedColumnDimensions']
          delete updateData['userSelectedRowDimensions']
          id = await this.updatePivotTableSettingsMutation(updateData, this.widget.widgetSettings.id)
        }
      }
      return id
    },

    async updatePivotTableSettingsMutation(data, where) {
      const res = await this.$apollo.mutate({
        mutation: PIVOT_TABLE_WIDGET_SETTINGS_UPDATE,
        variables: {
          data: data,
          where: {
            id: where,
          },
        },
      })
      return get(res, 'data.updatePivotTableWidgetSettings.id', null)
    },
  },
  apollo: {
    widgetTypes: {
      query: WIDGET_TYPES_QUERY,
    },
  },
}
</script>
