export default [
  {
    id: 'TABLE',
    minDimensions: 1,
    maxDimensions: Infinity,
    needsFleetViewDashboard: false,
  },
  {
    id: 'MULTI_CHART',
    minDimensions: 1,
    maxDimensions: 3,
  },
  {
    id: 'PIE_CHART',
    minDimensions: 1,
    maxDimensions: 1,
  },
  {
    id: 'MAP',
    minDimensions: 1,
    maxDimensions: 1,
  },
  {
    id: 'SANKEY_CHART',
    minDimensions: 1,
    maxDimensions: 1,
  },
  {
    id: 'TIMELINE_CHART',
    minDimensions: 1,
    maxDimensions: 1,
    needsSingleViewDashboard: true,
  },
  {
    id: 'ASSET_INFORMATION',
    minDimensions: 0,
    maxDimensions: 0,
    needsSingleViewDashboard: true,
  },
  {
    id: 'DASHBOARD_INFORMATION',
    minDimensions: 0,
    maxDimensions: 0,
  },
  {
    id: 'ERROR_LOG',
    minDimensions: 0,
    maxDimensions: 0,
  },
  {
    id: 'MULTI_DIMENSION',
    minDimensions: 1,
    maxDimensions: Infinity,
    needsFleetViewDashboard: false,
  },
  {
    id: 'CARD',
    minDimensions: 1,
    maxDimensions: 1,
    needsFleetViewDashboard: false,
  },
  {
    id: 'ERROR_STATISTICS',
    minDimensions: 1,
    maxDimensions: 1,
    needsFleetViewDashboard: false,
  },
  {
    id: 'HISTOGRAM',
    minDimensions: 1,
    maxDimensions: 1,
    needsFleetViewDashboard: false,
  },
  {
    id: 'CYCLE_TABLE',
    minDimensions: 1,
    maxDimensions: Infinity,
    needsFleetViewDashboard: false,
  },
  {
    id: 'PIVOT_TABLE',
    minDimensions: 1,
    maxDimensions: 11,
  },
]
