var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetStyled', {
    staticClass: "widget",
    class: {
      preview: _vm.isPreview,
      'preview-mobile': _vm.isMobilePreview
    },
    attrs: {
      "hasBorder": _vm.hasBorder,
      "isEditMode": _vm.isEditMode
    }
  }, [_vm.hasTitle ? _c('TitleStyled', [_vm._v(" " + _vm._s(_vm.widget.title) + " ")]) : _vm._e(), _c('MainStyled', {
    class: {
      'align-top': _vm.alignTop
    }
  }, [[_c(_vm.widgetComponent, {
    ref: "asyncWidget",
    tag: "component",
    attrs: {
      "widget": _vm.widget,
      "isPreview": _vm.isPreview,
      "isMobilePreview": _vm.isMobilePreview
    }
  })], false ? _c('WidgetIncompatibleStyled', [_vm._v(" " + _vm._s(_vm.$tc('messages.incompatibleDashboardType')) + " ")]) : _vm._e()], 2), _c('HeaderStyled', {
    staticClass: "widget-header",
    attrs: {
      "active": _vm.isEditMode
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade-in-left"
    }
  }, [_vm.isEditMode ? _c('ButtonStyled', {
    staticClass: "delete",
    on: {
      "click": _vm.confirmDeleteWidget
    }
  }, [_c('XIcon')], 1) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade-in-right"
    }
  }, [_vm.isEditMode ? _c('ButtonStyled', {
    staticClass: "menu",
    on: {
      "click": _vm.configureWidget
    }
  }, [_c('MoreVerticalIcon')], 1) : _vm._e()], 1)], 1), _c('FooterStyled', {
    attrs: {
      "active": _vm.isEditMode
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }