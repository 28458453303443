<template>
  <HeaderStyled>
    {{ title }}
  </HeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const HeaderStyled = styled('div')`
  ${flexCenter}
  text-transform: uppercase;
  grid-area: header;
  color: ${p => p.theme.colors.muted};
  font-weight: 100;
  font-size: 1.25rem;
  background: ${p => p.theme.colors.solidBG};
  @media (min-width: 768px) {
    background: none;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    HeaderStyled,
  },
}
</script>
