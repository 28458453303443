<template>
  <WidgetStyled
    class="widget"
    :class="{
      preview: isPreview,
      'preview-mobile': isMobilePreview,
    }"
    :hasBorder="hasBorder"
    :isEditMode="isEditMode"
  >
    <TitleStyled v-if="hasTitle"> {{ widget.title }} </TitleStyled>
    <MainStyled :class="{ 'align-top': alignTop }">
      <template>
        <component ref="asyncWidget" :is="widgetComponent" :widget="widget" :isPreview="isPreview" :isMobilePreview="isMobilePreview" />
      </template>
      <WidgetIncompatibleStyled v-if="false">
        {{ $tc('messages.incompatibleDashboardType') }}
      </WidgetIncompatibleStyled>
    </MainStyled>
    <HeaderStyled class="widget-header" :active="isEditMode">
      <transition name="fade-in-left">
        <ButtonStyled class="delete" @click="confirmDeleteWidget" v-if="isEditMode">
          <XIcon />
        </ButtonStyled>
      </transition>
      <transition name="fade-in-right">
        <ButtonStyled class="menu" @click="configureWidget" v-if="isEditMode">
          <MoreVerticalIcon />
        </ButtonStyled>
      </transition>
    </HeaderStyled>
    <FooterStyled :active="isEditMode"> </FooterStyled>
  </WidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'

import { MoreVerticalIcon, XIcon } from 'vue-feather-icons'

import { flexStartCenter, flexColumns, flexCenter } from '@styles/mixins'

import { ButtonRound } from '@styles/buttons'

import { TalpaLoaderWrapper } from '@common/components'

import WidgetRequirements from '@/utils/widgetRequirements'

import WidgetError from './Widget/WidgetError'

const WidgetStyled = styled('div')`
  ${flexColumns}
  position: relative;
  overflow: hidden;
  height: 100%;
  color: ${({ theme }) => theme.colors.normalFontColor};
  &:not(.preview) {
    border-radius: 8px;
    background-color: ${p => p.theme.colors.widgetBG};
    box-shadow: ${p => p.theme.colors.widgetShadow};
    border: 1px solid ${p => (p.hasBorder || p.isEditMode ? p.theme.colors.widgetBorder : 'none')};
  }
`

const HeaderStyled = styled('section')`
  ${flexCenter}
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0.5rem);
  height: 2rem;
  z-index: 1200;
  transition: background-color 0.25s ease, opacity 0.25s ease, transform 0.25s ease;
  padding: 0.25rem;
  opacity: ${p => (p.active ? 1 : 0)};
  background: ${({ theme, active }) => (active ? theme.colors.widgetEditBG : 'transparent')};
  transform: translateY(${p => (p.active ? 0 : '-2.5rem')});
  border-bottom: 1px solid ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
`

const FooterStyled = styled('section')`
  ${flexStartCenter}
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 0.5rem);
  z-index: 10;
  padding: 0.25rem;
  height: 2rem;
  transition: background-color 0.25s ease, opacity 0.25s ease, transform 0.25s ease;
  transition: min-height 0.25s ease, background-color 0.25s ease;
  opacity: ${p => (p.active ? 1 : 0)};
  background: ${({ theme, active }) => (active ? theme.colors.widgetEditBG : 'transparent')};
  transform: translateY(${p => (p.active ? 0 : '2.5rem')});
  border-top: 1px solid ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
`

const TitleStyled = styled('div')`
  width: 100%;
  ${flexCenter}
  min-height: 2rem;
  font-size: 22px;
  font-weight: 300;
`

const MainStyled = styled('section')`
  ${flexStartCenter}
  flex-grow: 1;
  overflow: auto;
  &.align-top {
    align-items: flex-start;
  }
`

const ButtonStyled = styled(ButtonRound)`
  background: transparent;
  padding: 0;
  color: ${p => p.theme.colors.primary};
  &:hover {
    color: ${p => p.theme.colors.secondary};
    background: ${p => chroma(p.theme.colors.white).alpha(0.5).css()};
  }
`

const WidgetIncompatibleStyled = styled('div')`
  ${flexCenter}
  width: 100%;
`

export default {
  inject: ['uiSettings'],
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    isMobilePreview: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    gridItemID: {
      type: String,
      required: true,
    },
    dashboardType: {
      type: String,
      required: true,
    },
  },
  components: {
    WidgetStyled,
    TitleStyled,
    MainStyled,
    HeaderStyled,
    FooterStyled,
    MoreVerticalIcon,
    XIcon,
    ButtonStyled,
    WidgetIncompatibleStyled,
  },
  data() {
    return {
      widgetComponent: null,
    }
  },
  computed: {
    isCompatible() {
      const type = get(this.widget, 'type', undefined)
      const reqs = WidgetRequirements.find(f => f.id === type)
      if (reqs) {
        const needsSingleViewDashboard = reqs.needsSingleViewDashboard
        const singleViewReq = needsSingleViewDashboard ? this.isSingleViewDashboard : true

        const needsFleetViewDashboard = reqs.needsFleetViewDashboard
        const fleetViewReq = needsFleetViewDashboard ? !this.isSingleViewDashboard : true
        return singleViewReq && fleetViewReq
      }
      return true
    },
    widgetComponentName() {
      const type = get(this.widget, 'type', 'unknown')
        .split('_')
        .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
        .join('')
      return type
    },
    isSingleViewDashboard() {
      const t = this.dashboardType
      return t === 'SINGLE_ASSET' || t === 'TEMPLATE_SINGLE_ASSET'
    },
    hasBorder() {
      return get(this.widget, 'type', 'unknown') !== 'CIRCLE'
    },
    alignTop() {
      return get(this.widget, 'type', 'unknown') === 'TIMELINE_CHART'
    },
    hasTitle() {
      return (
        !!get(this.widget, 'title', '') &&
        get(this.widget, 'type', 'unknown') !== 'TABLE' &&
        get(this.widget, 'type', 'unknown') !== 'DASHBOARD_INFORMATION' &&
        get(this.widget, 'type', 'unknown') !== 'PIVOT_TABLE'
      )
    },
  },
  watch: {
    widgetComponentName: {
      handler(type) {
        this.widgetComponent = () => {
          if (!type) {
            return {
              component: WidgetError,
            }
          }
          return {
            component: import('./' + type + '.vue'),
            loading: TalpaLoaderWrapper,
            error: WidgetError,
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    configureWidget() {
      this.$root.$emit(
        'activateOverlay',
        'WidgetSettingsOverlay',
        {
          dashboard: { type: this.dashboardType },
          widget: this.widget,
          mode: 'edit',
          gridItemID: this.gridItemID,
        },
        this.onCloseSettings,
      )
    },
    confirmDeleteWidget() {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Widget',
          onConfirm: this.delete,
        },
        this.onCloseSettings,
      )
    },
    onCloseSettings() {
      this.$emit('refreshDashboard')
    },
    delete() {
      this.$emit('deleteGridItem')
      return true
    },
    gridItemMoved() {
      const widget = get(this.$refs, 'asyncWidget')
      if (widget && typeof widget.gridItemMoved === 'function') {
        widget.gridItemMoved()
      }
    },
    gridItemResized() {
      const widget = get(this.$refs, 'asyncWidget')
      if (widget && typeof widget.gridItemResized === 'function') {
        widget.gridItemResized()
      }
    },
  },
}
</script>
