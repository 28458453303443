var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetSettingsOverlayStyled', {
    class: {
      'configure-dimensions': _vm.showDimensionsSelected
    }
  }, [_c('Header', {
    attrs: {
      "title": _vm.headerTitle
    }
  }), _c('MainStyled', {
    ref: 'main'
  }, [_c('DimensionsAvailable', {
    ref: 'available',
    attrs: {
      "dimensionsSelected": _vm.dimensionsSelected,
      "selectedWidget": _vm.selectedWidget
    },
    on: {
      "addDimension": _vm.addDimension,
      "removeDimension": _vm.removeDimension,
      "unselectWidgetType": _vm.unselectWidgetType
    }
  }), _vm.showDimensionsSelected ? _c('DimensionsSelected', {
    ref: 'selected',
    attrs: {
      "dimensionsSelected": _vm.dimensionsSelected,
      "widgetDimensions": _vm.widgetDimensions,
      "selectedWidget": _vm.selectedWidget
    },
    on: {
      "removeDimension": _vm.removeDimension,
      "toggleTargetValueEnabled": _vm.toggleTargetValueEnabled,
      "updateTargetValue": _vm.updateTargetValue,
      "updateChartType": _vm.updateChartType
    }
  }) : _vm._e(), _c('ConfigureWidget', {
    ref: 'widget',
    attrs: {
      "title": _vm.title,
      "selectedWidget": _vm.selectedWidget,
      "widgetSettings": _vm.widgetSettings,
      "widgetsAvailable": _vm.widgetsMapped,
      "dimensionsSelected": _vm.dimensionsSelected
    },
    on: {
      "update:title": function updateTitle($event) {
        _vm.title = $event;
      },
      "setWidgetType": _vm.setWidgetType,
      "updateTitle": function updateTitle($event) {
        _vm.title = $event;
      },
      "changeWidgetSettings": _vm.changeWidgetSettings
    }
  }), _c('WidgetPreview', {
    ref: 'preview',
    attrs: {
      "showWidgetPreview": _vm.showWidgetPreview,
      "gridItemID": _vm.gridItemID,
      "dashboardType": _vm.dashboardType,
      "widget": _vm.widgetPreviewObj
    }
  })], 1), _c('Footer', {
    attrs: {
      "canSave": _vm.canSave
    },
    on: {
      "save": _vm.confirm,
      "cancel": function cancel($event) {
        return _vm.$emit('close');
      },
      "scrollTo": _vm.scrollTo
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }