<template>
  <WidgetPreviewStyled :class="{ active: showWidgetPreview }">
    <HeaderStyled>
      <span>{{ $t('titles.widgetPreview') }}</span>
      <div class="switch">
        <button @click="setDeviceMode('mobile')" :class="{ active: isMobile }">
          <SmartphoneIcon />
        </button>
        <button @click="setDeviceMode('desktop')" :class="{ active: isDesktop }">
          <MonitorIcon />
        </button>
        <label id="showPreview" for="showPreview">
          {{ $tc('titles.showPreview') }}
        </label>
        <OnOffToggle id="showPreview" @toggle="showPreview = !showPreview" :alternativeStyle="true" :value="showPreview"> </OnOffToggle>
      </div>
    </HeaderStyled>
    <MainStyled v-if="showPreview">
      <transition name="fade">
        <Widget
          v-if="showWidgetPreview"
          :widget="widget"
          :isPreview="true"
          :isMobilePreview="isMobile"
          :isEditMode="false"
          :gridItemID="gridItemID"
          :dashboardType="dashboardType"
        />
        <div class="hint" v-else></div>
      </transition>
    </MainStyled>
  </WidgetPreviewStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import Widget from '@/components/Widgets/Widget'
import OnOffToggle from '@common/components/src/OnOffToggle'

import { SmartphoneIcon, MonitorIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

const WidgetPreviewStyled = styled('div')`
  grid-area: widget-preview;
  display: grid;
  margin: 0.5rem;
  overflow-y: auto;
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  border-radius: 0.5rem;
  grid-template-rows: 2rem 1fr;
  grid-template-columns: 1fr;
  opacity: 0;
  transition: opacity 0.5s ease;

  &.active {
    opacity: 1;
  }
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  position: relative;
  color: ${p => p.theme.colors.primary};
  border-bottom: 1px solid ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.lightGrey)};

  .switch {
    ${flexCenter}
    position: absolute;
    right: 0.5rem;
    button {
      ${buttonReset}
      ${flexCenter}
      color: ${({ theme }) => (theme.isDark ? theme.colors.navFontNormal : theme.colors.primary)};
      &:hover,
      &.active {
        color: ${({ theme }) => (theme.isDark ? theme.colors.primary : theme.colors.secondary)};
      }
    }

    #showPreview {
      margin-right: 5px;
    }
  }
`

const MainStyled = styled('div')`
  overflow: auto;
  background: ${({ theme }) => (theme.isDark ? theme.colors.mainBG : 'transparent')};
`

export default {
  props: {
    showWidgetPreview: {
      type: Boolean,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    gridItemID: {
      type: String,
      required: true,
    },
    dashboardType: {
      type: String,
      required: true,
    },
  },
  components: {
    WidgetPreviewStyled,
    MainStyled,
    HeaderStyled,
    Widget,
    SmartphoneIcon,
    MonitorIcon,
    OnOffToggle,
  },
  data() {
    return {
      deviceMode: 'desktop',
      showPreview: false,
    }
  },
  computed: {
    isMobile() {
      return this.deviceMode === 'mobile'
    },
    isDesktop() {
      return this.deviceMode === 'desktop'
    },
  },
  methods: {
    setDeviceMode(mode) {
      this.deviceMode = mode
    },
  },
}
</script>
