var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FooterStyled', [_c('ScrollersStyled', [_c('ButtonScrollStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('scrollTo', 1);
      }
    }
  }, [_vm._v(" 1 ")]), _c('ButtonScrollStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('scrollTo', 2);
      }
    }
  }, [_vm._v(" 2 ")]), _c('ButtonScrollStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('scrollTo', 3);
      }
    }
  }, [_vm._v(" 3 ")]), _c('ButtonScrollStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('scrollTo', 4);
      }
    }
  }, [_vm._v(" 4 ")])], 1), _c('ButtonStyled', {
    staticClass: "ok",
    attrs: {
      "disabled": !_vm.canSave
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.ok')) + " ")]), _c('ButtonStyled', {
    staticClass: "cancel",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.cancel')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }