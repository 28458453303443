var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetPreviewStyled', {
    class: {
      active: _vm.showWidgetPreview
    }
  }, [_c('HeaderStyled', [_c('span', [_vm._v(_vm._s(_vm.$t('titles.widgetPreview')))]), _c('div', {
    staticClass: "switch"
  }, [_c('button', {
    class: {
      active: _vm.isMobile
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceMode('mobile');
      }
    }
  }, [_c('SmartphoneIcon')], 1), _c('button', {
    class: {
      active: _vm.isDesktop
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceMode('desktop');
      }
    }
  }, [_c('MonitorIcon')], 1), _c('label', {
    attrs: {
      "id": "showPreview",
      "for": "showPreview"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('titles.showPreview')) + " ")]), _c('OnOffToggle', {
    attrs: {
      "id": "showPreview",
      "alternativeStyle": true,
      "value": _vm.showPreview
    },
    on: {
      "toggle": function toggle($event) {
        _vm.showPreview = !_vm.showPreview;
      }
    }
  })], 1)]), _vm.showPreview ? _c('MainStyled', [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showWidgetPreview ? _c('Widget', {
    attrs: {
      "widget": _vm.widget,
      "isPreview": true,
      "isMobilePreview": _vm.isMobile,
      "isEditMode": false,
      "gridItemID": _vm.gridItemID,
      "dashboardType": _vm.dashboardType
    }
  }) : _c('div', {
    staticClass: "hint"
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }