<template>
  <FooterStyled>
    <ScrollersStyled>
      <ButtonScrollStyled @click="$emit('scrollTo', 1)"> 1 </ButtonScrollStyled>
      <ButtonScrollStyled @click="$emit('scrollTo', 2)"> 2 </ButtonScrollStyled>
      <ButtonScrollStyled @click="$emit('scrollTo', 3)"> 3 </ButtonScrollStyled>
      <ButtonScrollStyled @click="$emit('scrollTo', 4)"> 4 </ButtonScrollStyled>
    </ScrollersStyled>
    <ButtonStyled class="ok" :disabled="!canSave" @click="$emit('save')">
      {{ $tc('actions.ok') }}
    </ButtonStyled>
    <ButtonStyled class="cancel" @click="$emit('cancel')">
      {{ $tc('actions.cancel') }}
    </ButtonStyled>
  </FooterStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { ButtonSolid as ButtonSolidStyled } from '@styles/buttons'

import { flexCenter } from '@styles/mixins'

const FooterStyled = styled('div')`
  ${flexCenter}
  justify-content: space-around;
  grid-area: footer;
  background: ${p => p.theme.colors.solidBG};
  @media (min-width: 768px) {
    background: none;
    justify-content: center;
    .cancel,
    .ok {
      margin: 0 1rem;
    }
  }
`

const ScrollersStyled = styled('div')`
  display: flex;
  justify-content: space-around;
  @media (min-width: 768px) {
    display: none;
  }
`

const ButtonStyled = styled(ButtonSolidStyled)`
  margin: 0;
`

const ButtonScrollStyled = styled(ButtonSolidStyled)`
  margin: 0.25rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`

export default {
  props: {
    canSave: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FooterStyled,
    ScrollersStyled,
    ButtonStyled,
    ButtonScrollStyled,
  },
}
</script>
